/* Use this file if you don't familiar with SASS
** This file will be compiled with the POSTCSS rules anyway after runing Gulp
*/
.views_slideshow_cycle_teaser_section {
width: 100% !important;
}
.views_slideshow_cycle_teaser_section {
  position: relative;
}
.views_slideshow_cycle_slide {
width: 100% !important;
height: auto !important;
}/*
.views-field-field-photos img {
width: 100% !important;
}
*/

/**
* Quick tabs - People:; overrides
*/
#quicktabs-people .item-list a#quicktabs-tab-people-0 {
  background-image: url(../images/people-list.png);
  background-image: url(../images/people-list.svg);
  margin-left: 10px;
}

#quicktabs-people .item-list li.active a#quicktabs-tab-people-0 {
  background-image: url(../images/people-list-on.png);
  background-image: url(../images/people-list-on.svg);
}

#quicktabs-people .item-list a#quicktabs-tab-people-1 {
  background-image: url(../images/people-grid.png);
  background-image: url(../images/people-grid.svg);
  margin-left: 10px;
}

#quicktabs-people .item-list li.active a#quicktabs-tab-people-1 {
  background-image: url(../images/people-grid-on.png);
  background-image: url(../images/people-grid-on.svg);
}

/* EU Cookie Compliance overrides */

#sliding-popup .popup-content #popup-text {
 max-width: 80%;
}    

#sliding-popup .eu-cookie-compliance-default-button {
 font-weight: 400;
}

/*.views-field-field-downloads-et {
  background-color: red;
}*/

/* custom file icons: PPTX; */
.custom-file-icon.pptx {
  background: url(../images/file-pptx.png) no-repeat 0 50%;
  background-image: url(../images/file-pptx.svg);
}
/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */

/*# sourceMappingURL=maps/shame.css.map */
